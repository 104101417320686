<template>
  <div>
    <el-container style="height: 100vh;">

      <Aside :isCollapse="isCollapse" :sideWidth="sideWidth"/>

      <el-container>
        <el-header style="border-bottom: 1px solid #ccc;">
          <Header :collapse-btn-class="collapseBtnClass" :collapse="collapse"/>
        </el-header>

        <el-main>
          <!--  表示当前页面的子路由会在router-view中展示  -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Aside from "@/components/Aside";
import Header from "@/components/Header";

export default {
  name: 'Layout',
  components: { Header, Aside },
  data() {
    return {
      collapseBtnClass: 'el-icon-s-unfold',
      isCollapse: true,
      sideWidth: 64,
    }
  },
  methods: {
    collapse() {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) { // 折叠
        this.sideWidth = 64
        this.collapseBtnClass = 'el-icon-s-unfold'
        // this.logoTextShow = false
      } else { // 展开
        this.sideWidth = 200
        this.collapseBtnClass = 'el-icon-s-fold'
      }
    },
    // headerStyle({row, column, rowIndex, columnIndex}) {
    //   return 'tableStyle'
    // },

  },

}
</script>

<style scoped>
.el-header {
  /*background-color: #B3C0D1;*/
  /*color: #333;*/
  line-height: 60px;
}

/*.tableStyle {*/
/*  background-color: #409dfd !important;*/
/*  color: #fff;*/
/*}*/

/deep/.el-main{
  padding-top:0;
}

.el-aside {
  color: #333;
}

/*/deep/ .el-table .cell {*/
/*  white-space: nowrap;*/
/*}*/

.btn-class {
  background-color: #303856FF;
  color: white;
}

.btn-class:hover {
  background-color: #303856AA;
  color: white;
}


</style>
