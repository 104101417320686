<template>
  <div style="font-size: 12px;  display: flex; align-items: center">
    <div style="flex: 1; font-size: 20px; display: flex">
      <div>
        <span :class="collapseBtnClass" style="cursor:pointer" @click="collapse"></span>
      </div>
      <div style="display: flex; align-items: center; margin-left: 20px">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ currentPathName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div style="height: 40px; line-height: 40px">
      <img style="width: 40px; height: 40px; border-radius: 50%; cursor: pointer;" src="../assets/avatar/avatar.jpeg" />
    </div>

    <el-dropdown trigger="click" style="width: 140px; height: 40px; cursor: pointer; display: flex; align-items: center">
      <span style="margin-left: 15px">
        {{ userZhName }}<i class="el-icon-arrow-down" style="margin-left: 10px"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>个人信息</el-dropdown-item>
        <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "Header",
  data(){
    return{

    }
  },
  props:{
    collapseBtnClass:String,
    collapse:Function,
  },
  computed:{
    currentPathName(){
      return this.$store.state.currentPathName
    },
    userZhName(){
      console.log("userZhName----",JSON.parse(localStorage.getItem("user")).userZhName)
      return JSON.parse(localStorage.getItem("user")).userZhName
    }
  },
  methods:{
    logout(){
      console.log("---logout----")
      localStorage.removeItem("user")
      this.$router.push("/login")
    },
  },
  watch:{
    currentPathName(newVal, oldVal){
      console.log(newVal)
    }
  }
}
</script>

<style scoped>
/deep/ .el-breadcrumb__inner {
  color: #288076;
}
/deep/ .el-breadcrumb__inner:hover {
  color: #66ba6a;
}
</style>