<template>
  <el-aside :width="sideWidth + 'px'"
            style="background-color: rgb(253,253,253);
                box-shadow: 2px 0 10px rgb(149,160,178)">
    <el-menu style="height: 100%; overflow-x: hidden"
             background-color="rgb(253,253,253)"
             :default-active="getCurrentIndex"
             text-color="#95A0B2FF"
             active-text-color="#F7F6FDFF"
             :collapse-transition="false"
             :collapse="isCollapse"
             :unique-opened="true"
             router
    >
      <div style="height: 60px; line-height: 60px; text-align: center; ">
        <span class="iconfont icon-new1"></span>
        <b style="margin-left: 10px; font-size: 16px; color:#333333" v-show="!isCollapse">招聘跟踪管理系统</b>
      </div>

      <el-menu-item index="/dashboard">
        <i class="el-icon-data-analysis"></i>
        <span>数据大盘</span>
      </el-menu-item>

      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>人才招聘</span>
        </template>
        <el-menu-item-group>
<!--          <el-menu-item index="/schoolRecruit">校园招聘</el-menu-item>-->
          <el-submenu index="/socialRecruit">
            <template slot="title">
              社会招聘
            </template>
            <el-menu-item-group>
              <el-menu-item index="/socialRecruit">人员信息管理</el-menu-item>
              <el-menu-item index="/postManage">岗位信息管理</el-menu-item>
              <el-menu-item index="/turnsManage">招聘批次管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>扩展功能</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/404">功能一</el-menu-item>
          <el-menu-item index="/404">功能二</el-menu-item>
<!--          <el-menu-item index="/404">功能三</el-menu-item>-->
<!--          <el-menu-item index="/404">功能四</el-menu-item>-->
<!--          <el-menu-item index="/404">功能五</el-menu-item>-->
<!--          <el-menu-item index="/404">功能六</el-menu-item>-->
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-aside>

</template>

<script>
import store from "@/store";

export default {
  name: "Aside",
  props: {
    isCollapse: Boolean,
    sideWidth: Number,
  },
  data(){
    return {
      currentIndex: '/dashboard'
    }
  },
  computed:{
    getCurrentIndex(){
      return localStorage.getItem("currentPath")
    }
  }
}
</script>

<style>
.el-menu-item.is-active {
  background-color: #c7e5c8 !important;
  color: #000 !important;
}

.el-menu-item:hover {
  background-color: #e2f1fc !important;
  color: #000 !important;
}

.el-submenu__title:hover {
  background-color: cornflowerblue;
}
</style>